import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export enum DisplayChoice {
  PointsBalance = 'pointsBalance',
  Rewards = 'availableRewards',
  ProgramName = 'programName',
}

export type ISettingsParams = {
  displayChoice: SettingsParamType.String;
  programNameLabel: SettingsParamType.Text;
  rewardsAvailableLabel: SettingsParamType.Text;
  pointsBalanceLabel: SettingsParamType.Text;
  beforeLoginLabel: SettingsParamType.Text;
  navigatePageId: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  displayChoice: {
    type: SettingsParamType.String,
    getDefaultValue: () => DisplayChoice.PointsBalance,
  },
  programNameLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  rewardsAvailableLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('status-widget.rewards-available.default-text'),
  },
  pointsBalanceLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('status-widget.points-balance.default-text'),
  },
  beforeLoginLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('status-widget.before-login.default-text'),
  },
  navigatePageId: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
});
