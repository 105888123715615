import { createEventHandler } from '@wix/tpa-settings';
import { ControllerFlowAPI, IControllerConfig } from '@wix/yoshi-flow-editor';

import { SettingsEvent, SettingsEvents, WidgetSettingsState } from '../constants';
import { ControllerProps } from '../Widget/types';
import { loadUserData } from './loadUserData';

export function createSettingsEventHandler(
  flowAPI: ControllerFlowAPI,
  setWidgetProps: (props: Partial<ControllerProps>) => void,
) {
  const componentData = flowAPI.controllerConfig.config.publicData.COMPONENT || {};
  const componentEventHandler = createEventHandler<SettingsEvents>(componentData);

  const subscribeToSettingsChanges = () => {
    componentEventHandler.on(SettingsEvent.StateChange, (state: WidgetSettingsState) =>
      setWidgetProps({ isLoggedIn: state === WidgetSettingsState.LoggedIn }),
    );

    componentEventHandler.on(SettingsEvent.DisplayChoiceChange, () =>
      loadUserData(flowAPI).then((userData) => {
        setWidgetProps({ ...userData });
      }),
    );
  };

  const handleSettingsConfigChange = (newConfig: IControllerConfig) => {
    const newComponentData = newConfig.publicData.COMPONENT || {};
    componentEventHandler.notify(newComponentData);
  };

  return { handleSettingsConfigChange, subscribeToSettingsChanges };
}
